import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import InfoIcon from '../../../../../assets/images/info_icon.svg';


function FormField(props) {
    const margin = props.marginClass || "";
    if (props.tooltip) {
        return (
            <div className={'form-group ' + margin + ' w-100'}>
                <label className="form-label">{props.label}</label>
                <img className="px-2" src={InfoIcon} data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title={props.tooltip} />
                <input name={props.name} id={props.id} required onChange={(event) => props.onChange(event)} className="form-control style-black" placeholder={props.placeholder} type={props.type} tooltip="true" autoComplete={props.autoComplete} />
            </div>
        )
    } else {
        return (
            <div className={'form-group ' + margin + ' w-100'}>
                <label className="form-label">{props.label}</label>
                <input name={props.name} id={props.id} required onChange={(event) => props.onChange(event)} className="form-control style-black" placeholder={props.placeholder} type={props.type} autoComplete={props.autoComplete} />
            </div>
        )
    }
}

function TextEntryQuestion(props) {
    return (
        <div className='form-group mb-4 w-100'>
            <label className="form-label">{props.label}</label>
            <input name={props.name} id={props.id} required onChange={(event) => props.onChange(event.target.value)} className="form-control" placeholder={props.placeholder} type={props.type} />
        </div>
    )
}

function MultipleChoiceQuestion(props) {
    return (
        <div className='form-group mb-4 w-100'>
            <label className="form-label onboarding-label">{props.questionText}</label>
            <div>
                {props.children}
            </div>
        </div>
    )
}


function RadioOption(props) {
    const classes = "form-check-input custom-radio-color"


    return (
        <div className="checkbox-wrapper my-2">
            <input
                className={classes}
                type='radio'
                name={props.name}
                id={props.id}
                value={props.value}
                onChange={() => props.onChange(props.value)}
            />
            <label className="form-check-label onboarding-label" htmlFor={props.id}>
                {props.label}
            </label>
        </div>
    )
}

function CheckboxOption(props) {
    const classes = "form-check-input custom-checkbox-color"

    return (
        <div className="checkbox-wrapper my-2">
            <input
                className={classes}
                type='checkbox'
                name={props.name}
                id={props.id}
                value={props.value}
                checked={props.checked}
                onChange={(event) => props.onChange(event.target.checked)}
            />
            <label className="form-check-label onboarding-label" htmlFor={props.id}>
                {props.label}
            </label>
        </div>
    )
}

function DropdownQuestion(props) {
    const placeholderSelection = props.placeholder && <DropdownOption isDisabled={true} value="-1" label={props.placeholder} />
    const marginClass = props.marginClass || "";
    return (
        <div className={'form-group ' + marginClass + ' w-100'}>
            {props.questionText ? <label className="form-label onboarding-label">{props.questionText}</label> : <></>}
            <select className="form-select custom-select-new" name={props.name} onChange={(event) => props.onChange(event.target.value)} id={props.id} value={props.value} defaultValue={props.defaultValue} aria-label={props.ariaLabel}>
                {placeholderSelection}
                {props.children}
            </select>
        </div>
    )
}

function DropdownOption(props) {
    return (
        <option disabled={props.isDisabled} className="form-control" value={props.value}>{props.label}</option>
    )
}


// A 100% width button with a purple gradient background, white text, and no border
function PrimaryButton(props) {
    const marginClass = props.marginClass || "";
    const cssClass = "btn btn-purple-gradient-color text-white w-100 " + marginClass

    return (
        <button type={props.buttonType} onClick={props.onClick} className={cssClass} disabled={props.isDisabled}>
            <div>{props.text}</div>
        </button>
    )
}

// A minimally sized button with a purple gradient background, white text, and no border
function PrimaryButtonSmall(props) {
    const marginClass = props.marginClass || "";
    const cssClass = "btn btn-small btn-purple-gradient-color min-width-content text-white " + marginClass

    return (
        <button type={props.buttonType} onClick={props.onClick} className={cssClass} disabled={props.isDisabled}>
            <div>{props.text}</div>
        </button>
    )
}

// A 100% width button with a gray gradient background, and gradient border white text, and no border
function SecondaryButton(props) {
    const marginClass = props.marginClass || "";
    const cssClass = !props.isDisabled ? ("secondary-border-outer w-100 " + marginClass) : ("secondary-border-outer disabled w-100 " + marginClass)

    return (
        <div className={cssClass}>
            <button type={props.buttonType} onClick={props.onClick} disabled={props.isDisabled} className='btn btn-gray-border-gradient-color w-100 text-white '>
                <div>{props.text} </div>
            </button>
        </div>
    )

}

// A minimally sized button with a gray gradient background, and gradient border white text, and no border
function SecondaryButtonSmall(props) {
    const marginClass = props.marginClass || "";
    const shrinkClass = !props.shrink ? " flex-shrink-0" : ""; // If not shrink, then add in flex-shrink-0
    const disabledClass = props.isDisabled ? " disabled" : "";
    const cssClass = "secondary-border-outer border-small " + marginClass + shrinkClass + disabledClass

    return (
        <div className={cssClass}>
            <button type={props.buttonType} onClick={props.onClick} disabled={props.isDisabled} className='btn btn-small btn-gray-border-gradient-color min-width-content text-white '>
                <div>{props.text} </div>
            </button>
        </div>
    )
}

function TertiaryButton(props) {
    const marginClass = props.marginClass || "";
    const cssClass = "btn btn-transparent-color text-mid-gray " + marginClass

    return (
        <button type={props.buttonType} onClick={props.onClick} disabled={props.isDisabled} className={cssClass}>
            <div>{props.text}</div>
        </button>
    )
}

// A minimally sized button with a transparent backgroudn and gray border and text
function TertiaryButtonSmall(props) {
    const marginClass = props.marginClass || "";
    const cssClass = "btn btn-small btn-transparent-color text-mid-gray " + marginClass

    return (
        <button type={props.buttonType} onClick={props.onClick} disabled={props.isDisabled} className={cssClass}>
            <div>{props.text}</div>
        </button>
    )
}

function OrSeparater() {
    return (
        <div className="d-flex flex-row justify-content-center align-items-center mt-4 mb-4">
            <div className="w-100">
                <hr className="bg-med-gray" />
            </div>
            <div className="px-2 text-white-50">or</div>
            <div className="w-100">
                <hr className="bg-med-gray" />
            </div>
        </div>
    )
}

function PreAuthPageContainer({ children }) {
    return (
        <div className="container-fluid bg-black-color">
            <div className='row d-flex justify-content-center align-items-center h-100'>
                {children}
            </div>
        </div>
    )
}

function LogInContainer({ children }) {
    return (
        <div className="col12">
            <div className=' mw-440  my-80px mx-auto'>  {/* This sets the width and margins */}
                {children}
            </div>
        </div>
    )

}

function LogInCard({ children, align = 'center', borderClass = '' }) {
    const alignClass = align === 'center' ? 'align-items-center' : 'align-items-start'
    const borderClassFinal = borderClass === '' ? 'gray-outline' : borderClass
    return (
        <div className={"card signup-card bg-radial-gray-color text-white " + borderClassFinal}>
            <div className={'cardbody p-4 p-sm-5 d-flex flex-column ' + alignClass + ' pcx-30 w-100'}>
                {children}
            </div>
        </div>
    )

}

function LogInCardBody({ children }) {
    return (
        <div className="card-body-2 px-2">
            {children}
        </div>
    )

}

function LogInForm({ children, onSubmit }) {
    return (
        <form onSubmit={(event) => onSubmit(event)} className="form bg-transparent-color no-outline text-white w-100 mt-5">
            {children}
        </form>
    )
}

function LogInCardTitle({ title }) {
    return (
        <h2 className="signup-title-font text-center mb-2">{title}</h2>
    )
}

function ForgotPasswordLink() {

    return (
        <div className="d-flex justify-content-end">
            <Link className="tertiary-link" to={{ pathname: "/forgotpassword" }}>Forgot Password?</Link>
        </div>
    )
}

function SignUpTertiary({ preLinkText = "Don't have an account? ", path = "/signup", linkText = "Sign Up" }) {
    return (
        <div className="flex-column align-items-center pcx-30 my-32px w-100">
            <div className="sign-up-tertiary">{preLinkText} <Link className="secondary-link" to={{ pathname: path }}>{linkText}</Link></div>
        </div>
    )
}

function ErrorMessage({ errors }) {
    // Check if it's empty, vs. a string, vs. an array
    if (!errors) {
        // Empty or Null.
        return <></>
    } else if (errors.constructor === String && errors.length > 0) {
        return (
            <div className='row mw-880 px-2'>
                <div className="alert alert-danger mt-1">
                    {errors}
                </div>
            </div>
        )
    } else if (errors.constructor === Array && errors.length > 0) {
        return (
            <div className='row mw-880 px-2'>
                <div className="alert alert-danger mt-1">
                    {/* Okay, then we map each error to a new line  */}
                    {errors.map((error, index) => {
                        return <div key={index}>{error}</div>
                    })}
                </div>
            </div>
        )

    } else {
        return <></>
    }
}

function NoticeMessage({ notices }) {
    // Check if it's empty, vs. a string, vs. an array
    if (!notices) {
        // Empty or Null.
        return <></>
    } else if (notices.constructor === String) {
        return (
            <div className='row mw-880 px-2'>
                <div className="alert alert-secondary mt-1">
                    {notices}
                </div>
            </div>
        )
    } else if (notices.constructor === Array) {
        return (
            <div className='row mw-880 px-2'>
                <div className="alert alert-secondary mt-1">
                    {/* Okay, then we map each error to a new line  */}
                    {notices.map((notice, index) => {
                        return <div key={index}>{notice}</div>
                    })}
                </div>
            </div>
        )
    }
}

function TurnBackgroundBlackComponent() {
    return (
        <div className="bg-black-color full-page-background" />
    )
}


export {
    FormField, TextEntryQuestion, MultipleChoiceQuestion, RadioOption, CheckboxOption, DropdownQuestion, DropdownOption, PrimaryButton, PrimaryButtonSmall,
    SecondaryButton, SecondaryButtonSmall, TertiaryButton, TertiaryButtonSmall,
    OrSeparater, LogInCard, LogInCardTitle, ErrorMessage, NoticeMessage, TurnBackgroundBlackComponent, LogInContainer, LogInCardBody, LogInForm, ForgotPasswordLink, SignUpTertiary, PreAuthPageContainer
}